// import { useEffect } from "react";
import "./App.css";
import ReactGA from "react-ga4";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Footer from "./Layout/Footer/Footer";
import Header from "./Layout/Header/Header";
import Router from "./Router";
import "./common.scss";
// import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';
// import { useLocation } from "react-router-dom";
// import useCustomScript from "./useCustomScript";


function App() {

   // Initialize with your GA4 Measurement ID
   ReactGA.initialize('G-QW9KHFYH0T'); // Replace with your Measurement ID
   // Optionally, send a pageview for the initial page load
   ReactGA.send("pageview");

  // const location = useLocation();

  // useEffect(() => {
  //   // Function to set background images
  //   const setBackgroundImages = () => {
  //     $(".background_bg").each(function() {
  //       const attr = $(this).attr('data-img-src');
  //       if (attr) {
  //         $(this).css('background-image', `url(${attr})`);
  //       }
  //     });
  //   };

  //   // // Function to initialize carousels
 

  //   // Function to initialize animations
  //   const ckScrollInit = (items, trigger) => {
  //     items.each(function() {
  //       const ckElement = $(this),
  //             AnimationClass = ckElement.attr('data-animation'),
  //             AnimationDelay = ckElement.attr('data-animation-delay');

  //       ckElement.css({
  //         '-webkit-animation-delay': AnimationDelay,
  //         '-moz-animation-delay': AnimationDelay,
  //         'animation-delay': AnimationDelay,
  //         opacity: 0
  //       });

  //       const ckTrigger = trigger ? trigger : ckElement;

  //       new window.Waypoint({
  //         element: ckTrigger[0], // Use the raw DOM element
  //         handler: function() {
  //           ckElement.addClass('animated').css('opacity', '1');
  //           ckElement.addClass('animated').addClass(AnimationClass);
  //         },
  //         offset: '90%',
  //         triggerOnce: true
  //       });
  //     });
  //   };

  //   // Initialize components
  //   setBackgroundImages();
  
  //   ckScrollInit($('.animation'));
  //   ckScrollInit($('.staggered-animation'), $('.staggered-animation-wrap'));

  //   // Trigger preloader fade out if needed
  //   $(".preloader").delay(700).fadeOut(700).addClass('loaded');

  // }, [location]); 


  return (
    <>
      <Header/>
      <ScrollToTop />
      <Router/>
    <Footer/>
    </>
  );
}

export default App;
